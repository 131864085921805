/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
// @import "@angular/material/prebuilt-themes/purple-green.css";

html, body {
  min-height: 100%;
  height: 100%;
  line-height: normal;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

mat-sidenav-container {
  height: 100%;
}

.mat-sidenav-content::-webkit-scrollbar, body::-webkit-scrollbar, mat-sidenav.sidenav::-webkit-scrollbar, .main-container::-webkit-scrollbar {
  height: 6px;
  width: 6px
}

.mat-sidenav-content::-webkit-scrollbar-trac, body::-webkit-scrollbar-track, mat-sidenav.sidenav::-webkit-scrollbar-trac, .main-container::-webkit-scrollbar-trac {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
}

.mat-sidenav-content::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb, mat-sidenav.sidenav::-webkit-scrollbar-thumb, .main-container::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  outline: 1px solid #333
}

.snackbar-shared {
  font-weight: bold;
}

.snackbar-default {
  background-color: #3c4252eb;
  @extend .snackbar-shared;
}

.snackbar-alert {
  background-color: #f44336eb;
  @extend .snackbar-shared;
}

.snackbar-ok {
  background-color: #33691eeb;
  @extend .snackbar-shared;
}
